import * as React from "react";
import { Fade } from 'react-reveal'
import { Helmet } from 'react-helmet'

import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const LegalPage = () => {
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <main className="bg-black">
      <Helmet htmlAttributes={{ lang: 'fr-FR' }}>
        <title>
          Mentions légales | Ksatra SEM - Fullstack designer freelance à
          Toulouse
        </title>
        <meta name="description" content="Mentions légales" />
      </Helmet>

      {!isLoading && <Navbar bgColor="bg-black" textColor="text-white" />}

      <div className="mx-6 mt-24 text-white lg:px-16 xl:px-40">
        <Fade bottom>
          <h1 className="mb-8 font-serif text-4xl lg:mb-16 lg:text-6xl">
            Mentions légales
          </h1>
          <div className="flex flex-col gap-8">
            <div className="">
              <p>Ksatra SEM - Fullstack designer freelance</p>
              <p>SIRET : 82252525900021</p>
              <p>Code APE : 6201Z</p>
              <p>Toulouse, France</p>
              <p>Contact : contact@ksatra.fr</p>
              <p>
                Site web : <a href="/">www.ksatra.fr</a>
              </p>
            </div>

            <div>
              <h3 className="pb-4 font-serif text-2xl">Hébergement</h3>
              <p>L’hébergement du site est assuré par Vercel</p>
              <p>Paris, France</p>
              <a href="https://vercel.com/" target="_blank">
                https://vercel.com
              </a>
            </div>

            <div>
              <h3 className="pb-4 font-serif text-2xl">
                Conditions d'utilisation
              </h3>
              <p>
                Ce site www.ksatra.fr est proposé en différents langages web
                (HTML5, Javascript, CSS, etc…) pour un meilleur confort
                d’utilisation et un graphisme plus agréable. Nous vous
                recommandons de recourir à des navigateurs modernes comme Google
                Chrome, Microsoft Edge, Firefox, Safari, Brave.
              </p>
            </div>

            <div>
              {' '}
              <h3 className="pb-4 font-serif text-2xl">Cookies</h3>
              <p>
                Le site www.ksatra.fr peut-être amené à vous demander
                l’acceptation des cookies pour des besoins de statistiques et
                d’affichage. Un cookie est une information déposée sur votre
                disque dur par le serveur du site que vous visitez. Il contient
                plusieurs données qui sont stockées sur votre ordinateur dans un
                simple fichier texte auquel un serveur accède pour lire et
                enregistrer des informations.
              </p>
            </div>

            <div>
              {' '}
              <h3 className="pb-4 font-serif text-2xl">Crédits</h3>
              <p>
                Photo de la section Design de la page d'accueil : Amélie
                Mourichon - Unsplash. <br />
                Photo de la section Code de la page d'accueil : Mohammad Rahmani
                - Unsplash.
              </p>
            </div>
          </div>
        </Fade>
      </div>

      <Fade bottom>
        <Footer />
      </Fade>
    </main>
  )
}

export default LegalPage;
